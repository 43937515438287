body {
  margin: 0;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
}

.tab {
  color: rgba(58, 53, 65, 0.87);
  font-weight: 400;
  display: flex;
  padding: 9px 14px 9px 22px;
  align-items: center;
}

.tab.active {
  background-image: linear-gradient(98deg, #a777ff, #7149C6 94%);
  color: white;
  box-shadow: '0px 4px 8px -4px rgb(58 53 65 / 42%)';
  border-radius: 0 30px 30px 0;
}

.active-profile {
  background-image: linear-gradient(98deg, #a777ff, #7149C6 94%);
  color: white !important;
  box-shadow: '0px 4px 8px -4px rgb(58 53 65 / 42%)';
  border-radius: 0 30px 30px 0;
}

.side-icons {
  filter: brightness(100);
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: gray !important;
}


.css-1vynwu8-MuiSvgIcon-root {
  color: rgba(58, 53, 65, 0.87);
  cursor: pointer;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.order-status {
  font-size: 18px;
  border: none;
}

.css-w66kx-MuiChip-root {
  background-color: #4cd964 !important;
}



.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgba(195, 188, 188, 0.87) !important;
}

.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.MuiButton-contained {
  text-transform: none !important;
}

.MuiButton-outlined {
  text-transform: none !important;
}

.white-icon {
  color: white;
}

.css-cktaik.Mui-error{
 color: none;
}


.canvas-container {
  height: 70vh;
  width:50vw;
  
}

.canvas-container1{
  height: 70vh;
  width:25vw;
}

